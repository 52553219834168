;(function ($) {
    $(function () {
        $.fn.getClasses = function (isString) {
            var ca = this.attr("class");
            var rval = [];
            if (ca && ca.length && ca.split) {
                ca = jQuery.trim(ca);
                ca = ca.replace(/\s+/g, " ");
                rval = ca.split(" ");
            }
            return (isString) ? rval.join(" ") : rval;
        };
    });
})(jQuery);
